import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-starthere2',
  templateUrl: './starthere2.component.html',
  standalone: true,
  imports: [RouterModule],
  styleUrls: ['./starthere2.component.scss'],
})
export class Starthere2Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
