import { Component } from '@angular/core';
import { Location } from '@angular/common'; // Import ViewportScroller
import { Starthere2Component } from '../components/homepage2/starthere2/starthere2.component';

@Component({
  selector: 'app-salesteam',
  standalone: true,
  imports: [Starthere2Component],
  templateUrl: './salesteam.component.html',
  styleUrl: './salesteam.component.scss',
})
export class SalesteamComponent {
  constructor(private location: Location) {}

  onClose(): void {
    this.location.back();
  }
}
