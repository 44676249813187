
<div class="back_button">
  <!-- <button mat-raised-button color=primary (click)="back()">Go Back</button> -->
  <button class="btn btn-border-pop" (click)="onClose()">Go back</button>
</div>


<div class="on-display-title">Meet Our Extraordinary Sales Team</div>

<div class="staff_containers">
  <div class="single_staff_container">
    <img
      src="./../../../assets/DaleBatchelor.jpeg"
      class="head_shot"
      alt="heritage staff pic"
    />
    <div class="staff_name">Dale Batchelor</div>
    <div class="staff_title">Sales Consultant</div>
    <div class="staff_bio">
      Dale brings a rich experience beginning with work in the Service
      Department - a significant benefit to his customers. He has an innate
      ability to “listen” to the customer’s needs, wants, and desires, and most
      importantly to their “budget” constraints. Dale is focused on creating an
      exceptional home buying experience and has the tools of the trade to make
      this happen for each of his valued customers.
    </div>

    <a href="mailto: info@heritagehomecenter.com">
      <img
        src="./../../../assets/logos/email-logo.png"
        class="staff_email_logo"
        alt="email logo"
      />
    </a>
  </div>

  <!-- remove Jeff Oct 17/22 -->
  <!-- <div class="single_staff_container">
    <img
      src="./../../../assets/JeffClancy.jpeg"
      class="head_shot"
      alt="heritage staff pic"
    />
    <div class="staff_name">Jeff Clancy</div>
    <div class="staff_title">Sales Consultant</div>
    <div class="staff_bio">
      Jeff Clancy is a Seattle native who joined Heritage and brings over 25
      years of industry experience, including 12 years in real estate. He is
      passionate about finding you the perfect home and specializes in working
      with mobile home communities. He helps customers navigate through
      government permits and regulations, dealing with codes and regulations of
      the delivery area.
    </div>

    <a href="mailto: info@heritagehomecenter.com">
      <img
        src="./../../../assets/logos/email-logo.png"
        class="staff_email_logo"
        alt="email logo"
      />
    </a>
  </div> -->
  <div class="single_staff_container">
    <img
      src="./../../../assets/James.png"
      class="head_shot"
      alt="heritage staff pic"
    />
    <div class="staff_name">James Murillo</div>
    <div class="staff_title">Office Manager & Sales Consultant</div>
    <div class="staff_bio">
      James has expertise in both Manufactured Home Service and Sales, making
      him organized and adaptable. Dedicated to customer satisfaction, he excels
      at guiding clients in designing their ideal homes and explaining
      everything in detail. His talent lies in his people-centric approach
      ensuring you get exactly what you want.
    </div>

    <a href="mailto: james@heritagehomecenter.com">
      <img
        src="./../../../assets/logos/email-logo.png"
        class="staff_email_logo"
        alt="email logo"
      />
    </a>
  </div>
  <div class="single_staff_container">
    <img
      src="./../../../assets/Eric.png"
      class="head_shot"
      alt="heritage staff pic"
    />
    <div class="staff_name">Eric Novoa</div>
    <div class="staff_title">Sales Consultant, Yo hablo español</div>
    <div class="staff_bio">
      Eric is a natural people person bringing years of top sales talent to
      Heritage. With his years of experience in sales and customer service, Eric
      is a bright star in the industry and he will impress you with follow
      through and friendly manner. Eric is fluent in English and Spanish - Yo
      hablo español!
    </div>

    <a href="mailto: info@heritagehomecenter.com">
      <img
        src="./../../../assets/logos/email-logo.png"
        class="staff_email_logo"
        alt="email logo"
      />
    </a>
  </div>
</div>
<app-starthere2></app-starthere2>